import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="デザイン原則 (Amebaらしさ3原則) - 原則" mdxType="SEO" />
    <PageTitle title="デザイン原則 (Amebaらしさ3原則)" enTitle="Design Principles" mdxType="PageTitle" />
    <p>{`デザイン原則は「Amebaらしさ」を届けるためにどのように設計、デザインをするかの「約束事」です。`}</p>
    <p>{`Amebaの価値やパーソナリティをユーザーが感じられるようにするため、私たちがどのように考え、どのように行動すべきかの指針にしてください。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5b69408a80192fbd4deca758f70d0edd/29114/IGx4I-39242%3A4323.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC4ElEQVQozz2Q3U9TBxiHz58w/wHJNFojjBst0My5LX6wG+OFG3GLN7rEZbthi/FCthgVRFqqCIUQiS4iMehwjQthBl1l1CCgfIgwxGF76Ac9tZ62p/TztD3nMT1B3uR987v4vc/7IVxwmTjrqqBheAf1zm9ZU+4AaXQddF2jFH0j/QyM30fNq2SzWdLpNJlMmqKmo6gpgskQoVSYaFxBOOcqxzG6iQezH5NIuvkQJZimaaTUNO5XbibfPDO0rusUtSJFDbS8B/IvKegQTr9FfPsKIRjp5NlrMz7ZCaTQ9QKaXjSg3ugKE94pgqIf5V2M6cAcHllcH1gqOXJqhEQ2h0+Z5NK/ZgRVaWdCfETpuJJHW9/QFwtwbtRG/VADw0sjRtYPneGCu5WgEtqAlnpuTzsZXOzD5q5EGJw6yrGblSi5kkMmnx03zD3Tvfzw9ym+uvUNphYzppYqQ//44DTXnt82PNr6j0vgjid2mh5/grC38wua/ypjdvkXSFpBWyCZKXD5n4vU3T1OTedBLG0Hqb6y39BH+o9x+o+fyKiZjX+7PO2cGNjLb4+2Ieyw7aHC/jk1bSZ+vn+KRE4lrEhMLIzRPX6DascBqi7vY7f9S2octXQ97eLeZDOSIhmwUc9DDvdu5qRzCzOLHyFU2vew02ahrMnMluZq+me6CcQ99D0ZIJlK8uvDJrZbzZisZhqGGyEPKSWLpIRJZBJMB+a5+9LB4Ity8n4BoaL1UyyOWpzzg8xJC6yuSSxLXkTRR0SWueRuo+7eCer+/J7Gx3a8KyuEJIk3AZFoLGZsWdRhYOYQEa+AUNvzNROBKVLFNHFVYTUVRnznIxQPs7T6P93jv3N2pIXzo610jV1nTlzAHwkwPOti3vufATwz1Mju9nKezmxC2Nq8i46xHgPmXwsSzkQoFApEozFkOUo8qrC4ssRr/zIxOYYcieL1iVistVRbD/DdrZPstNVgavmMqqsW3gO/7cCTeBRSXgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5b69408a80192fbd4deca758f70d0edd/2aaaf/IGx4I-39242%3A4323.webp 160w", "/static/5b69408a80192fbd4deca758f70d0edd/85e47/IGx4I-39242%3A4323.webp 320w", "/static/5b69408a80192fbd4deca758f70d0edd/75198/IGx4I-39242%3A4323.webp 640w", "/static/5b69408a80192fbd4deca758f70d0edd/691bc/IGx4I-39242%3A4323.webp 960w", "/static/5b69408a80192fbd4deca758f70d0edd/223e5/IGx4I-39242%3A4323.webp 1280w", "/static/5b69408a80192fbd4deca758f70d0edd/c58b7/IGx4I-39242%3A4323.webp 1920w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5b69408a80192fbd4deca758f70d0edd/69538/IGx4I-39242%3A4323.png 160w", "/static/5b69408a80192fbd4deca758f70d0edd/72799/IGx4I-39242%3A4323.png 320w", "/static/5b69408a80192fbd4deca758f70d0edd/6af66/IGx4I-39242%3A4323.png 640w", "/static/5b69408a80192fbd4deca758f70d0edd/d9199/IGx4I-39242%3A4323.png 960w", "/static/5b69408a80192fbd4deca758f70d0edd/21b4d/IGx4I-39242%3A4323.png 1280w", "/static/5b69408a80192fbd4deca758f70d0edd/29114/IGx4I-39242%3A4323.png 1920w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/5b69408a80192fbd4deca758f70d0edd/6af66/IGx4I-39242%3A4323.png",
              "alt": "Amebaらしさには3つの原則(敬意を表そう・心を動かそう・快く迎え入れよう)が含まれており、それぞれが関連していることを円形で表現しているイラスト",
              "title": "Amebaらしさには3つの原則(敬意を表そう・心を動かそう・快く迎え入れよう)が含まれており、それぞれが関連していることを円形で表現しているイラスト",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "敬意を表そう",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%95%AC%E6%84%8F%E3%82%92%E8%A1%A8%E3%81%9D%E3%81%86",
        "aria-label": "敬意を表そう permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`敬意を表そう`}</h2>
    <p><strong parentName="p">{`私たちがユーザーに何かを伝えたいとき、 自分たちの都合を押しつけていませんか？`}</strong></p>
    <p>{`ユーザーが大事にしていることはひとりひとり異なります。その価値観を尊重し、寄り添うことが必要です。ひとりひとりの価値観を把握するためには、あらゆる視点でユーザーを深く知ることが重要です。 ユーザーの行動を観察・分析し、その背景にある気持ちを理解してください。 ユーザーを理解し、その価値観を尊重する。敬意を表しましょう。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "262px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/198b46642b40dc5ea889e50ae2292afd/8ff13/IGx4I-40357%3A6866.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/198b46642b40dc5ea889e50ae2292afd/2aaaf/IGx4I-40357%3A6866.webp 160w", "/static/198b46642b40dc5ea889e50ae2292afd/7dec2/IGx4I-40357%3A6866.webp 262w"],
              "sizes": "(max-width: 262px) 100vw, 262px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/198b46642b40dc5ea889e50ae2292afd/69538/IGx4I-40357%3A6866.png 160w", "/static/198b46642b40dc5ea889e50ae2292afd/8ff13/IGx4I-40357%3A6866.png 262w"],
              "sizes": "(max-width: 262px) 100vw, 262px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/198b46642b40dc5ea889e50ae2292afd/8ff13/IGx4I-40357%3A6866.png",
              "alt": "軽いお辞儀をしているスーツを着た人のイラスト",
              "title": "軽いお辞儀をしているスーツを着た人のイラスト",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "心を動かそう",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%BF%83%E3%82%92%E5%8B%95%E3%81%8B%E3%81%9D%E3%81%86",
        "aria-label": "心を動かそう permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`心を動かそう`}</h2>
    <p><strong parentName="p">{`私たちがユーザーに反応を返すとき、 ただ事実だけを伝えていませんか？`}</strong></p>
    <p>{`ユーザーは何かを求めてサービスに訪れます。ただ「使ってもらう」だけでなく、その時々でユーザーがサービスに共感できるよう心がけてください。Amebaを単なるシステムとして機能させず、ひとりの人間としての情緒を持たせてください。扱う言葉や表現に人間味や親しみを込めましょう。 私たちもまたユーザーに共感し、その感情に適した言葉をかける。心を動かしましょう。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "255px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/181b76dfa394db58ca595f4b82481a0c/b8c8f/IGx4I-40357%3A6918.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "105.62500000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/181b76dfa394db58ca595f4b82481a0c/2aaaf/IGx4I-40357%3A6918.webp 160w", "/static/181b76dfa394db58ca595f4b82481a0c/7220c/IGx4I-40357%3A6918.webp 255w"],
              "sizes": "(max-width: 255px) 100vw, 255px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/181b76dfa394db58ca595f4b82481a0c/69538/IGx4I-40357%3A6918.png 160w", "/static/181b76dfa394db58ca595f4b82481a0c/b8c8f/IGx4I-40357%3A6918.png 255w"],
              "sizes": "(max-width: 255px) 100vw, 255px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/181b76dfa394db58ca595f4b82481a0c/b8c8f/IGx4I-40357%3A6918.png",
              "alt": "両手を上げジャンプをしている人のイラスト",
              "title": "両手を上げジャンプをしている人のイラスト",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "快く迎え入れよう",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%BF%AB%E3%81%8F%E8%BF%8E%E3%81%88%E5%85%A5%E3%82%8C%E3%82%88%E3%81%86",
        "aria-label": "快く迎え入れよう permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`快く迎え入れよう`}</h2>
    <p><strong parentName="p">{`私たちがユーザーを迎えるとき、 ユーザーは自分の意思で行動・会話ができていますか？`}</strong></p>
    <p>{`様々な個性、多様な環境のユーザーがAmebaに訪れます。壁を感じさせないよう快く受け入れ、困っているときには手を差し伸べてください。友人に話しかけるような気軽さと、リズムの良いやりとりが理想です。ただし極端に馴れ馴れしい話し方や、一方的な伝え方にならないように心がけてください。 はじめての人にも常連の人にも、常に親しみやすく。快く迎え入れましょう。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "257px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7c95ce37809598c9d9091e7b1bd8369c/96e35/IGx4I-40357%3A6973.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "114.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7c95ce37809598c9d9091e7b1bd8369c/2aaaf/IGx4I-40357%3A6973.webp 160w", "/static/7c95ce37809598c9d9091e7b1bd8369c/a7963/IGx4I-40357%3A6973.webp 257w"],
              "sizes": "(max-width: 257px) 100vw, 257px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7c95ce37809598c9d9091e7b1bd8369c/69538/IGx4I-40357%3A6973.png 160w", "/static/7c95ce37809598c9d9091e7b1bd8369c/96e35/IGx4I-40357%3A6973.png 257w"],
              "sizes": "(max-width: 257px) 100vw, 257px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7c95ce37809598c9d9091e7b1bd8369c/96e35/IGx4I-40357%3A6973.png",
              "alt": "両手を広げあいさつするリアクションしている人のイラスト",
              "title": "両手を広げあいさつするリアクションしている人のイラスト",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      